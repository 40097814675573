import gql from 'graphql-tag'

enum Currency {
  GEL,
  USD,
  EUR,
}

enum OccuarenceType {
  MONTH,
}

enum SubscriptionStatuses {
  SUBSCRIBED,
  CANCELED,
  PENDING,
}

type SubscriptionProduct = {
  id: String
  price: Number
  currency: Currency
  occuarenceType: OccuarenceType
  occurrenceNumber: number
  status: String
  createDate: String
  updateDate: String
  deleteDate: String
}



export const GET_ALL_SUBSCRIPTIONS = gql`
  query {
    getSubscriptionProducts {
      data {
        id
        price
        currency
        occuarenceType
        occurrenceNumber

        status
        createDate {
          date
        }
      }
    }
  }
`
export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscriptionProduct($input: CreateSubscriptionProductInput!) {
    createSubscriptionProduct(input: $input) {
      price
      currency
      occuarenceType
      occurrenceNumber
      status
    }
  }
`

export const DELETE_SUBSCRIPTION = gql`
  mutation DeleteSubscriptionProduct($id: String!) {
    deleteSubscriptionProduct(id: $id) {
      id
    }
  }
`
export const ATTACH_SUBSCRIPTION = gql`
mutation addSubscriptionByAdmin(   $userId: String
    $productId: String
   ) {
  addSubscriptionByAdmin(userId: $userId, productId: $productId  ) {
      id
}
}`

export const ACTIVATE_SUBSCRIPTION = gql`
mutation(   $subscriptionId: String!
  $orderId: String!
 ) {
activateAdminSubscription(subscriptionId: $subscriptionId, orderId: $orderId  ) {
    id
}
}`


export const GET_ACTIVE_SUB = gql`
query( $userId: String ) {
  getAttachedSubs(userId: $userId, ) {
      id
      product{
        price
        occurrenceNumber
      }
}
}`


export const CANCEL_SUB = gql`
mutation( $id: String!, $userId: String  ) {
  cancelByAdmin(id: $id,  userId: $userId )
}`

export const GET_SUBSCRIPTION_PRODUCTS = gql`
query  {
  getSubscriptionProducts {
      data {
  id,
  price,
currency,
  occuarenceType,
  occurrenceNumber,
  status,
      }
}
}`

export const UPDATE_SUBSCRIPTION_PRODUCT = gql`
mutation updateSubscriptionProduct($id: String!, $input: UpdateSubscriptionProductInput!){
  updateSubscriptionProduct(id: $id, input: $input){
    id
  }
}
`
